<template>
    <div class="box">
        <to-history :prop-message="title"></to-history> 
        <Add :invoiceId="invoiceId"></Add>
    </div>
    
</template>

<script>
import add from '../../scrm_pc/order/invoiceAdd'  
export default {
    components: {
        'Add': add  //将别名demo 变成 组件 Demo
    },
    data() {
        return {
            invoiceId: "",
            title:this.$route.query.title
        }
    },
    mounted: function(){
        // console.log(this.$route.query.id);
        // this.orderId = this.$route.query.id;
    },
}

</script>
<style scoped>
    .box{
        height: 100vh;
        background-color: #fff;
        padding: 15px;
    }
</style>
